.row-group-0 {
  background-color: #17163c !important;
  color: white !important;
}

.row-group-1 {
  background-color: #999 !important;
}

.row-group-2 {
  background-color: #ccc !important;
}

.row-group-3 {
  background-color: #dfdfdf !important;
}

.row-group-4 {
  background-color: #eaeaea !important;
}

.ag-row-group,
.ag-row-group-leaf-indent {
  margin-left: 0 !important;
  padding-left: 0 !important;
}

.ag-row-pinned{
  font-weight: 700;
  text-transform: uppercase;
}

/* hover */

.ag-row-hover {
  /* putting in !important so it overrides the theme's styling as it hovers the row also */
  background-color: #dfdfff !important;
  color: black !important;
}

.ag-column-hover {
  background-color: #f4f1d4;
  color: black;
}

.row-group-0 .ag-column-hover {
  background-color: #343187;
  color: white;
}

.row-group-1 .ag-column-hover {
  background-color: #b3b3b3;
}

.row-group-2 .ag-column-hover {
  background-color: #e6e6e6;
}

.ag-row-hover .ag-column-hover {
  background-color: #dfd783;
  color: black;
}

.ag-floating-top {
  pointer-events: none !important;
}


.removeContextMenuItemFont {
  color: red;
  font-weight: 500;
}
