/* iPad 3 & 4 Media Queries - Retina iPad in portrait & landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  @supports (-webkit-touch-callout: none) {
    html {
      position: fixed;
      height: 100%;
      overflow: hidden;
    }

    body {
      width: 100vw;
      height: 100vh;
      overflow-y: scroll;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;
    }
  }
}

/* iPad 3 & 4 Media Queries - Retina iPad in landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  @supports (-webkit-touch-callout: none) {
    html {
      position: fixed;
      height: 100%;
      overflow: hidden;
    }

    body {
      width: 100vw;
      height: 100vh;
      overflow-y: scroll;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;
    }
  }
}

/* iPad 3 & 4 Media Queries - Retina iPad in portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  @supports (-webkit-touch-callout: none) {
    html {
      position: fixed;
      height: 100%;
      overflow: hidden;
    }

    body {
      width: 100vw;
      height: 100vh;
      overflow-y: scroll;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;
    }
  }
}

/* 2.Modal Popup css */
body.modal-open > div[class='fade modal-backdrop show'] {
  z-index: 1060;
}

body.modal-open > div[class='fade modal show'] {
  z-index: 1070;
}

@media only screen and (max-height: 712px) {
  #columns-dropdown + div {
    line-height: 95%;
    font-size: x-small;
  }
}

.padding4px {
  padding: 4px;
}

body{overflow-y:hidden}
