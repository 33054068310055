.droppable-zone {
    min-width: 110px;
    padding: 20px 10px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    list-style-type: none;
    margin: 6px;
  }

  .droppable-zone-title {
    color: #cccccc;
    font-size: small;
  }


  .not-allowed {
    cursor: not-allowed;
  }
  .not-allowed [role=button] {
    cursor: not-allowed;
  }
  .not-allowed label {
    cursor: not-allowed;
  }
